import {Chip} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as React from 'react'

import {CommonProps} from '@utils/types'

import useStyles from './style.hook'
/**
 * ValidationChipBox
 */
interface Validation {
	label: string
	value: string
	regex: RegExp
}
interface ValidationChipBoxProps extends CommonProps {
	validations: Validation[]
}

const ValidationChipBox: React.FC<ValidationChipBoxProps> = ({
	id,
	className,
	validations,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<div id={id} className={classNames(className && className)}>
			{validations.map((v, i) => (
				<Chip
					key={i}
					className={classNames(
						classes.notValidated,
						v.value.match(v.regex) && classes.validated
					)}
					label={v.label}
					// @ts-ignore
					icon={
						v.value.match(v.regex) && (
							<CheckIcon className={classes.checkIcon} />
						)
					}
				/>
			))}
		</div>
	)
}

ValidationChipBox.propTypes = {
	validations: PropTypes.any,
	id: PropTypes.any,
	className: PropTypes.any,
}

export default ValidationChipBox
