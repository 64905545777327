import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside ValidationChipBox functional component
 */

const useStyles = makeStyles((theme) => ({
	notValidated: {
		backgroundColor: '#f1f2f6',
		color: '#7e7e80',
		fontSize: '0.75rem',
		fontWeight: 600,
		marginRight: theme.spacing(1.5),
		marginBottom: theme.spacing(1.625),
	},
	validated: {
		backgroundColor: '#caf0d8',
		color: '#56847f',
		paddingLeft: theme.spacing(0.5),
	},
	checkIcon: {
		height: '1.125rem',
		width: '1.125rem',
		color: '#56847f',
	},
}))

export default useStyles
