export const TWO_SPECIAL_CHARS_REGEX = new RegExp(
	/(?:[^`!@#$%^&*\-_=+'/.,]*[`!@#$%^&*\-_=+'/.,]){2}/
)

export const TWO_UPPERCASE_REGEX = new RegExp(/(?:[^A-Z]*[A-Z]){2}/)

export const TWO_DIGIT_REGEX = new RegExp(/(?:[^0-9]*[0-9]){2}/)

export const TEN_CHARS_REGEX = new RegExp(/^.{10,}$/)

export const ONLY_NUMBERS = new RegExp(/^[0-9]+$/)
