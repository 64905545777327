import {TFunction} from 'i18next'
import * as Yup from 'yup'

import {
	TEN_CHARS_REGEX,
	TWO_DIGIT_REGEX,
	TWO_SPECIAL_CHARS_REGEX,
	TWO_UPPERCASE_REGEX,
} from '@utils/constants/regex-constants'
import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

export const setPasswordSchema = (t: TFunction): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		currentPassword: Yup.string().required(
			t(FormValidationErrorMessages.REQUIRED)
		),
		password: Yup.string()
			.matches(
				TWO_SPECIAL_CHARS_REGEX,
				t(FormValidationErrorMessages.PATTERN_MATCH)
			)
			.matches(
				TWO_UPPERCASE_REGEX,
				t(FormValidationErrorMessages.PATTERN_MATCH)
			)
			.matches(TWO_DIGIT_REGEX, t(FormValidationErrorMessages.PATTERN_MATCH))
			.matches(TEN_CHARS_REGEX, t(FormValidationErrorMessages.PATTERN_MATCH))
			.required(t(FormValidationErrorMessages.REQUIRED))
			.when('currentPassword', (currentPassword: string, schema: any) => {
				return schema.test({
					test: (password: string) => currentPassword !== password,
					message: t(FormValidationErrorMessages.CURRENT_PASSWORD_EQUAL),
				})
			}),
		confirmPassword: Yup.string()
			.required(t(FormValidationErrorMessages.REQUIRED))
			.when('password', (password: string, schema: any) => {
				return schema.test({
					test: (confirmPassword: string) => password === confirmPassword,
					message: t(FormValidationErrorMessages.CONFIRM_PASSWORD_NOT_EQUAL),
				})
			}),
	})
