import {Box, Grid, Typography} from '@material-ui/core'
import classNames from 'classnames'
import {Formik} from 'formik'
import PropTypes from 'prop-types'
import React, {ReactNode, useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import ValidationChipBox from '@components/ValidationChipBox'
import {
	CustomButtonComponent as CustomButton,
	ErrorMessageBoxComponent as ErrorMessageBox,
	PasswordInputComponent as PasswordInput,
	CyclisHeaderComponent as CyclisHeader,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {
	setPassword,
	setPasswordInital,
} from '@templates/SetPassword/set-password-slice'
import {setPasswordSchema} from '@templates/SetPassword/set-password-validation'
import {
	TEN_CHARS_REGEX,
	TWO_DIGIT_REGEX,
	TWO_SPECIAL_CHARS_REGEX,
	TWO_UPPERCASE_REGEX,
} from '@utils/constants/regex-constants'
import {LanguageContext} from '@utils/context'
import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * SetPassword
 */
interface SetPasswordProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

interface SetPasswordFormFields {
	password: string
	currentPassword: string
	confirmPassword: string
}

const SetPassword: React.FC<SetPasswordProps> = () => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const dispatch = useDispatch()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const setPasswordLoading = useSelector(
		(state: RootState) => state.setPassword.loading
	)
	const setPasswordSuccess = useSelector(
		(state: RootState) => state.setPassword.success
	)
	const setPasswordError = useSelector(
		(state: RootState) => state.setPassword.error
	)

	const initialValues = {password: '', currentPassword: '', confirmPassword: ''}

	const submitForgotPassword = async (
		values: SetPasswordFormFields
	): Promise<void> => {
		dispatch(setPassword(values.currentPassword, values.password))
	}

	useEffect(() => {
		if (setPasswordSuccess) {
			dispatch(setPasswordInital())
			dispatch(navigate(`/${activeLanguage}/app`))
		}
	}, [activeLanguage, setPasswordSuccess])

	const SetPasswordForm: React.FC = () => (
		<Box className={classes.centerContainer}>
			<Box className={classNames(classes.titleBox, classes.gutter)}>
				<Typography variant={'h2'} className={classes.title}>
					{t('SetPasswordTitle')}
				</Typography>
				<Typography className={classes.subTitle} variant={'body1'}>
					{t('SetPasswordSubTitle')}
				</Typography>
			</Box>
			{setPasswordError && (
				<ErrorMessageBox
					id="set-password-error-message-box"
					className={classes.errorMessageBox}
					errorMessage={t('SetPasswordError')}
				/>
			)}
			<Formik
				initialValues={initialValues}
				validationSchema={setPasswordSchema(t)}
				onSubmit={submitForgotPassword}
			>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					errors,
					touched,
				}): ReactNode => (
					<form onSubmit={handleSubmit}>
						<PasswordInput
							label={t('SetPasswordCurrentPasswordLabel')}
							className={classes.input}
							error={
								(errors.currentPassword && touched.currentPassword) as boolean
							}
							helperText={
								(touched.currentPassword && errors.currentPassword) as string
							}
							helperTextId={
								errors.currentPassword && 'current-password-helper-text'
							}
							propsToDelegate={{
								name: 'currentPassword',
								type: 'password',
								value: values.currentPassword,
								onChange: handleChange,
								onBlur: handleBlur,
								fullWidth: true,
							}}
						/>
						<PasswordInput
							label={t('LoginPasswordLabel')}
							error={(errors.password && touched.password) as boolean}
							helperText={(touched.password && errors.password) as string}
							helperTextId={errors.password && 'password-helper-text'}
							propsToDelegate={{
								name: 'password',
								type: 'password',
								value: values.password,
								onChange: handleChange,
								onBlur: handleBlur,
								fullWidth: true,
							}}
						/>
						<ValidationChipBox
							className={classes.validationBox}
							validations={[
								{
									label: t('SetPasswordTwoSpecialCharacters'),
									value: values.password,
									regex: TWO_SPECIAL_CHARS_REGEX,
								},
								{
									label: t('SetPasswordTwoUppercaseCharacters'),
									value: values.password,
									regex: TWO_UPPERCASE_REGEX,
								},
								{
									label: t('SetPasswordTwoDigits'),
									value: values.password,
									regex: TWO_DIGIT_REGEX,
								},
								{
									label: t('SetPasswordTenCharacters'),
									value: values.password,
									regex: TEN_CHARS_REGEX,
								},
							]}
						/>
						<PasswordInput
							label={t('SetPasswordConfirmPasswordLabel')}
							error={
								(errors.confirmPassword && touched.confirmPassword) as boolean
							}
							helperText={
								(touched.confirmPassword && errors.confirmPassword) as string
							}
							helperTextId={
								errors.confirmPassword && 'confirm-password-helper-text'
							}
							propsToDelegate={{
								name: 'confirmPassword',
								type: 'password',
								onChange: handleChange,
								onBlur: handleBlur,
								fullWidth: true,
							}}
						/>
						<Grid item md={6}>
							<CustomButton
								id={'set-password-save-button'}
								className={classNames(classes.formActionButton, classes.gutter)}
								text={t('SetPasswordSaveButton')}
								propsToDelegate={{
									type: 'submit',
									disabled: Boolean(
										!values.currentPassword ||
											!values.password ||
											!values.confirmPassword ||
											errors.currentPassword ||
											errors.password ||
											errors.confirmPassword ||
											setPasswordLoading
									),
									fullWidth: true,
								}}
							/>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	)

	return (
		<Box className={classes.mainContainer}>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			<Grid className={classes.grid} spacing={6} container direction="row">
				<Grid item md={4} sm={2} xs={1} />
				<Grid item md={4} sm={8} xs={10}>
					<SetPasswordForm />
				</Grid>
				<Grid item md={4} sm={2} xs={1} />
			</Grid>
		</Box>
	)
}

SetPassword.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
}

export default SetPassword
